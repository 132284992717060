.App {
  text-align: center;
}

.sign_in {
  margin-top: 10px;
}

/* #images{  
  text-align:center;
  margin:auto;
} */

/* img{
  width:150px;
  height:150px;
  display: block;
  
}*/

img:hover {
  background-color: aqua;
}

/* .upload{
  width:50px;
  border: 1px solid black;
  border-radius: 25px;
} */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.backdrop img {
  display: block;
  width: 60%;
  height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

/* body { 
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
} */

.logo_img {
  margin:10px;
  height: 27px;
  width: 27px;
}

.header {
  overflow: hidden;
  /* background-color: #f1f1f1; */
  /* padding: 20px 10px; */
  position: fixed;
  top: 0;
  width: 100%;
}

.header a { 
  float: left;
  color: black;
  /* text-align: center; */
  text-decoration: none;
  border-radius: 4px;
}

.header-right {
  float: right;
}

.body {
  margin-top: 100px;
}

.align_right{
  text-align: right;
}

.image:hover {
  opacity: 0.9;
  background-color: #f1f1f1;
}

.image img{
  /* max-width:365px;
  max-height:150px;
  width: auto;
  height: auto;
  margin: 10px 20px 10px 20px */

  /* width: 150px;
  height: 150px; */
  /* overflow: "hidden"; */
  margin: 10px 0px 10px 0px;
  width: 90%;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .image_metadata {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    font-size: 10px;
    /* border: 1px solid black; */
    margin: 10px 0px 10px 10px;
    width: 90%;
    line-height: 0;
  }
}

@media only screen and (min-width: 768px) {
  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .image_metadata {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    font-size: 15px;
    /* border: 1px solid black; */
    margin: 0px 15px 10px 15px;
    width: 90%;
    line-height: 0;
  }
}